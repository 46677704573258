/*
========================
Colors
========================
*/

// Fonts
$link-color: #06f;
$font-color: #333333;

// Border
$border-color: #e1e1e1;

// Commom colors
$primary-color: #f47014;
$background-color: #f6f6f6;
$success-color: #12ae45;
$warn-color: #e13737;
$info-color: #2647ab;
$alert-color: #c39102;

@mixin primary-gradient{
  background: #f47014;
  background: -moz-linear-gradient(left, #f47014 0%, #ea1e23 100%);
  background: -webkit-linear-gradient(left, #f47014 0%,#ea1e23 100%);
  background: linear-gradient(to right, #f47014 0%,#ea1e23 100%);
}

@mixin primary-gradient-vertical{
  background: #f47014;
  background: -moz-linear-gradient(bottom, #f47014 0%, #ea1e23 100%);
  background: -webkit-linear-gradient(bottom, #f47014 0%,#ea1e23 100%);
  background: linear-gradient(to top, #f47014 0%,#ea1e23 100%);
}

// Background classes
.bg-alert{
  background: $alert-color;
}

.bg-success{
  background: $success-color;
}

.bg-info{
  background: $info-color;
}

.bg-warn{
  background: $warn-color;
}

.bg-primary{
  background: $primary-color;
}

//Color classes
.color-alert{
  color: $alert-color;
}

.color-success{
  color: $success-color;
}

.color-info{
  color: $info-color;
}

.color-warn{
  color: $warn-color;
}

.color-primary{
  color: $primary-color;
}
