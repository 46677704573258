/*
========================
Offcam
========================
*/

.offcam{
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background: black;
	justify-content: center;
	align-items: center;
	display: flex;
}

.offcam__wrapper{
	padding: 20px;
	max-width: 400px;
}

.offcam__image{
	max-width: 150px;
	width: 100%;
	margin: 0 auto 20px auto;
	display: block;
}

.offcam__title{
	color: #fff;
	width: 100%;
	font-weight: normal;
	text-align: center;
}

.offcam__description{
	color: rgba(#fff, 0.4);
	text-align: center;
}
