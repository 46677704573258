/*
========================
Forms
========================
*/

//Inputs
$input-background: #fff;
$input-border-color: #cccccc;

//Focus
$input-border-color-focus: #06f;
$input-background-color-focus: #fff;

//Fieldset
$fieldset-border-color: #d2d2d2;

//Disabled
$disabled-background: lighten(#4c556b, 61%);

/* Fieldset and label
======================== */
label{
  display: block;
  margin: 0 0 5px 0;
}

fieldset{
  padding: 15px;
  border: solid $fieldset-border-color 2px;
  margin: 0 0 30px 0;
}

legend{
  font-weight: bold;
  margin: 0 0 0 30px;
  padding: 0 15px 0 15px;
}

/* Inputs and textarea
======================== */
.form-control{
  font-family: inherit;
  font-size: 1em;
  width: 100%;
  height: 36px;
  padding: 3px 12px 3px 12px;
  transition: border .2s linear 0s;
  border: solid 2px $input-border-color;
  border-radius: 3px;
  background: $input-background;
  color: #333;

  &:focus{
    border: solid 2px $input-border-color-focus;
    background: $input-background-color-focus;
    outline: none;
  }

  &[disabled]{
    cursor: no-drop;
  }
}

textarea.form-control{
  font-family: inherit;
  min-height: 100px;
  padding: 10px 15px 10px 15px;

  &[disabled]{
    cursor: no-drop;
    background: $disabled-background;
  }
}

/* State input
======================== */
.form-control_state_error{
  border-color: $warn-color;
  background-color: lighten($warn-color, 43%);
}

.form-control_state_success{
  border-color: $success-color;
  background-color: lighten($success-color, 60%);
}

/* Extra types of inputs
======================== */
.form-control-file{
  font-family: inherit;
  font-size: .9em;
  width: 100%;
  padding: 6px;
  cursor: pointer;
  border: solid $input-border-color 1px;
  background: white;
  border-radius: 3px;
}

.form-control-color{
  width: 100px;
  border: solid $input-border-color 2px;
  border-radius: 3px;
  height: 100px;
  display: block;
  padding: 3px;
  background: none;
  cursor: pointer;
}

.form-control-range{
  border: solid $input-border-color 2px;
  width: 100%;
  display: block;
  border-radius: 3px;
  padding: 15px 15px 15px 15px;
}

/* Select
======================== */
.form-select{
  font-family: inherit;
  font-size: 1em;
  width: 100%;
  height: 35px;
  padding: 2px 1px 2px 12px;
  cursor: pointer;
  transition: border .2s linear 0s;
  border: solid 2px $input-border-color;
  border-radius: 3px;
  background: $input-background;

  &[disabled]{
    cursor: no-drop;
    background: $disabled-background;
  }

  &:focus{
    border: solid 2px $input-border-color-focus;
    outline: none;
  }

  &::after{
    content: "teste";
  }
}

/* Divider
======================== */
.form-divider{
  margin: 0 0 20px 0;
}

/* Message
======================== */
.form-message_error{
  color: $warn-color;
  font-size:1em;
  display: block;
}

.form-message_success{
  color: darken($success-color, 5%);
  font-size:1em;
  display: block;
}
