/*
========================
Checkbox
========================
*/

$check-border-color: #333;
$check-background-actived: #000;

.form-checkbox{
  margin: 0 0 10px 0;

  input[type=checkbox]{
    display: none;
  }

  input[type=checkbox][disabled] + label::before{
    cursor: no-drop;
    opacity: .2;
  }

  input[type=checkbox] + label{
    display: inline-block;
    margin: 0;
    cursor: pointer;
  }

  input[type=checkbox] + label::before{
    position: relative;
    top: 9px;
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 0 7px 0 0;
    content: "";
    cursor: pointer;
    border: solid $check-border-color 2px;
    box-shadow: inset 0 0 0 3px #fff;
  }

  input[type=checkbox]:checked + label::before{
    background: $check-background-actived;
  }
}
