/*
========================
Finish
========================
*/

.finish{
  width: 100%;
  padding: 40px 20px 40px 20px;
  text-align: center;
  background: #fff;
  max-width: 420px;
}

.finish__icon{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4em;
  color: #fff;
  border-radius: 100px;
  margin: 0 auto 40px auto;
}

.finish__icon--success{
  color: #00A201;
}

.finish__icon--error{
  color: #f83244;
}

.finish__title{
  display: block;
  width: 100%;
  font-size: 2em;
  text-align: center;
  margin: 0 0 5px 0;
}

.finish__message{
  display: block;
  width: 100%;
  font-size: 1.1em;
  text-align: center;
  max-width: 300px;
  margin: 0 auto 40px auto;
}

.finish__close{
  margin: 0 auto;
}

