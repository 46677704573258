/*
========================
Reset
========================
*/

*,
*:after,
*:before{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img{
  visibility: visible;
  vertical-align: top;
}

html, body{
  font-family: arial, sans-serif;
  font-size: 1em;
  overflow-x: auto;
  background: #f6f6f6;
  height: 100vh;
}
