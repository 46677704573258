/*
========================
Radio
========================
*/

$radio-border-color: #333;
$radio-background-actived: #000;

.form-radio{
  margin: 0 0 10px 0;

  input[type=radio]{
    display: none;
  }

  label{
    display: inline-block;
    margin: 0;
    cursor: pointer;
  }

  label::before{
    position: relative;
    top: 9px;
    display: inline-block;
    width: 27px;
    height: 27px;
    margin: 0 5px 0 0;
    content: "";
    cursor: pointer;
    border: solid $radio-border-color 2px;
    border-radius: 100%;
    box-shadow: inset 0 0 0 3px #fff;
  }

  input[type=radio]:checked + label::before{
    background: $radio-background-actived;
  }

  input[type=radio][disabled] + label::before{
    cursor: no-drop;
    opacity: .2;
  }
}
