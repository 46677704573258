/*
========================
Admin
========================
*/

.admin{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 10000000;
  display: none;
}

.admin__title{
  font-size: 1.7em;
  border-bottom: $border-color solid 3px;
  padding: 0 0 15px 0;
}

.admin__logo{
  width: 180px;
  display: block;
  margin: 10px auto 50px auto;
}

.admin__wrapper{
  width: 500px;
  margin: 0 auto;
  padding: 30px;
  background: $background-color;
  box-shadow: 10px 10px 20px 0px rgba(#000, 0.2);
}

.admin__divider{
  margin: 0 0 30px 0;

  &:last-child{
    margin: 0;
  }
}

.admin__divider__item{
  margin: 0 0 5px 0;

  &:last-child{
    margin: 0;
  }
}

.admin__input-logout{
  margin: 0 0 15px 0;
}

.admin__form-error{
  margin: 0 0 15px 0;
  display: none;
  font-weight: bold;
}
