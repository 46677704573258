/*
========================
QrCode
========================
*/

.ponto{
	@include primary-gradient();
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ponto__wrapper{
	max-width: 100%;
	width: 100%;
	padding: 0 0 0 0;
	display: flex;
	position: relative;
	height: 100vh;

	@media(max-width: 800px){
		display: block;
	}
}
