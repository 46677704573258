/*
========================
Confirm
========================
*/

.confirm{
  position: absolute;
  background: rgba(#000, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 10000;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 0 20px 0 20px;
}

.confirm__wrapper{
  width: 100%;
  max-width: 500px;
  padding: 20px 0px 20px 0;
  position: relative;
  margin: auto;
}

.confirm__content{
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px #000;
}

.confirm__name{
  font-size: 1.5em;
  margin: 0 0 20px 0;
}

.confirm__register{
  font-size: 1em;
  display: block;
  margin: 0px auto 5px auto;
  border-bottom: solid #e4e4e4 1px;
  padding: 0 0 5px 0;
}

.confirm__location{
  margin: 0 0 20px 0;
}

.confirm__photo{
  width: 100%;
  margin: 0 0 10px 0;
  transform: scaleX(-1);
  filter: FlipH;
  border-radius: 3px;
}

.confirm__submit{
  border: none;
  border-radius: 2px;
  width: 100%;
  padding: 20px;
  margin: 0 0 10px 0;
  cursor: pointer;
  font-size: 1.4em;
  color: #fff;
  background: #0069FF;
  transition: all 0.3s linear 0s;
  justify-content: center;
  align-items: center;
  outline: none;
  font-family: inherit;

  img{
    width: 25px;
    margin-right: 10px;
  }

  &:hover{
    background: #002F72;
  }
}

