/*
========================
Login form
========================
*/

.login-form{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
}

.login-form__logo{
	max-width: 250px;
	margin: 0 0 40px 0;
}

.login-form__title{
	font-size: 1.3em;
	text-align: center;
}

.login-form__wrapper{
	width: 100%;
	max-width: 400px;
	border: solid #fff 1px;
	background: $background-color;
	padding: 30px 30px 20px 30px;
	box-shadow: 10px 10px 20px 0px rgba(#000, 0.2);
	position: relative;
}

.login-form__error{
	color: red;
	display: block;
	margin: 5px 0 0 0;
}

.login-form__submit{
	margin: 0 0 30px 0;
}

.login-form__error-box{
	background: red;
	padding: 5px 15px 5px 15px;
	color: #fff;
	border-radius: 2px;
}
