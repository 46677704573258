/*
========================
Off location
========================
*/

.location-off{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.location-off__image{
  width: 100%;
  max-width: 50px;
  margin: 0 0 20px 0;
}

.location-off__title{
  color: #fff;
  font-size: 1.2em;
  padding: 0 30px 0 30px;
  font-weight: normal;
}

.location-off__description{
  color: rgba(#fff, 0.4);
  padding: 0 30px 0 30px;
  width: 100%;
  text-align: center;
}
