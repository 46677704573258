/*
========================
Table
========================
*/

$table-border-color: #d2d2d2;
$table-caption-border-color: #000;

.table{
  table{
    width: 100%;
    margin: 0 0 20px 0;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: left;
  }

  caption{
    font-weight: bold;
    margin: 0 0 -1px 0;
    padding: 10px 0 10px 0;
    border-bottom: solid $table-caption-border-color 2px;
  }

  td{
    width: 2%;
    padding: 10px 0 10px 0;
    border-bottom: solid $table-border-color 1px;
  }

  th{
    font-weight: bold;
    padding: 10px 0 10px 0;
    text-align: left;
    border-bottom: solid $table-border-color 1px;
  }
}
