/*
========================
Location
========================
*/

.location{
  background: #181818;
  width: 100%;
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.location__map{
  height: 100%;
  width: 100%;
}

.google-map{
	width: 100%;
	height: 100%;
	border: none;
}

.open-google-maps{
  color: #fff;
  border-top: solid #fff 1px;
  background: #3366FF;
  font-weight: bold;
  width: 100%;
  padding: 10px;
}
