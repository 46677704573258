/*
========================
Sidebar
========================
*/

.sidebar{
  width: 600px;
  background: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include primary-gradient-vertical();

  @media(max-width: 800px){
    width: 100%;
  }
}

.sidebar__header{
  padding: 20px 30px 0 30px;
  margin: 0 0 0 0;
}

.sidebar__logo{
  display: block;
  width: 100%;
  max-width: 160px;
  margin: 0 0 10px 0;
}

.sidebar__adm{
  color: #fff;
  margin: 0 0 10px 0;
  font-weight: bold;
  display: flex;
  font-size: 0.9em;
}

.sidebar__logout{
  color: rgba(#000, 0.6);
  font-weight: bold;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 20px;
  display: flex;
  text-transform: uppercase;

  &:hover{
    text-decoration: none;
    color: rgba(#000, 1);
  }
}

.sidebar__logout__icon{
  font-size: 20px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
