/*
========================
Buttons
========================
*/

$button-background: #000;
$button-color: #fff;
$button-border-color: #000;

button,
input[type=submit]{
  appearance: none;
}

.btn{
  font-size: .94em;
  display: inline-block;
  height: 38px;
  line-height: 32px;
  margin: 0 0 8px 0;
  padding: 0 20px 0 20px;
  cursor: pointer;
  transition: border .1s linear 0s;
  text-align: center;
  vertical-align: middle;
  color: $button-color;
  border: solid $button-border-color 1px;
  border-radius: 3px;
  background: $button-background;
  font-family: inherit;

  &:hover{
    text-decoration: none;
    background: darken($button-background, 100%);
  }

  &:active{
    color: $button-color;
    background: darken($button-background, 15%);
  }
}

a.btn{
  line-height: 34px;
}

button.btn[disabled],
a.btn[disabled],
input[type=submit].btn[disabled]{
  cursor: no-drop;
  opacity: .4;
}

// Custom buttons

@mixin customButton($color){
  background: $color;
  border: solid darken($color, 5%) 1px;

  &:hover{
    background: darken($color, 5%);
  }

  &:active{
    background: darken($color, 7%);
    border: solid darken($color, 7%) 1px;
  }
}

.btn-type--success{
  @include customButton($success-color);
}

.btn-type--warn{
  @include customButton($warn-color);
}

.btn-type--alert{
  @include customButton($alert-color);
}

.btn-type--info{
  @include customButton($info-color);
}
