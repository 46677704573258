/*
========================
Type
========================
*/

body,
html{
  font-family: 'Roboto', sans-serif;
  font-size: 0.96em;
  line-height: 1.6em;
  word-wrap: break-word;
  color: $font-color;
}

p{
  margin: 0 0 20px 0;
}

h1{
  font-size: 2em;
  line-height: 1.2em;
}

h2{
  font-size: 1.8em;
  line-height: 1.3em;
}

h3{
  font-size: 1.6em;
  line-height: 1.3em;
}

h4{
  font-size: 1.4em;
  line-height: 1.5em;
}

h5{
  font-size: 1.2em;
  line-height: 1.5em;
}

h6{
  font-size: 1em;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0 0 20px 0;
}

ul{
  margin: 0 0 20px 0;
  padding-left: 20px;
  list-style: disc;
}

ol{
  margin: 0 0 20px 0;
  padding-left: 20px;
}

blockquote{
  font-style: oblique;
  line-height: 1.8em;
  margin: 0 0 20px 0;
  padding: 0 0 0 10px;
  border-left: solid $border-color 5px;
}

a{
  text-decoration: none;
  color: $link-color;

  &:hover{
    text-decoration: underline;
  }

  &:active{
    color: darken( $link-color, 20% );
  }
}

hr{
  height: 1px;
  margin: 20px 0 20px 0;
  border: none;
  border-top: solid $border-color 1px;
}

pre{
  font-family: Consolas, monaco, monospace;
  font-size: 1em;
}

small{
  font-size: .8em;
}

mark{
  padding: 1px 7px 1px 7px;
  color: $font-color;
  border-radius: 1px;
  background: lighten($link-color, 40%);
}
