/*
========================
Qr form
========================
*/

.qrform{
  padding: 25px;
}

.qrform__label{
  color: #fff;
  font-size: 1em;
  line-height: 1.3em;
  margin: 0 0 10px 0;
}

.qrform__label--active{
  font-weight: bold;
  font-size: 1.1em;
}

.qrform__control{
  border: solid #fff 3px;
  box-shadow: 3px 3px 10px 0px rgba(#000, 0.2);
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: bold;
  color: #484848;
  margin: 0 0 10px 0;
  transition: all 0.3s linear 0s;

  &:focus{
    border: solid #fff 3px;
  }
}

.qrform__control--success{
  background: #00A201;
  color: #fff;

  &:focus{
    background: #00A201;
  }
}

.qrform__control--error{
  background: #ff1818;
  color: #fff;

  &:focus{
    background: #ff1818;
  }
}

.qrform__cancel{
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  background: #000;
  font-family: inherit;
  padding: 5px 15px 6px 15px;
  border-radius: 3px;
}
