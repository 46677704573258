/*
========================
Cam
========================
*/

.cam{
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media(max-width: 800px){
    height: auto;
  }

  video{
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    text-align: center;
    display: block;
    background: black;
    box-shadow: 0px 0px 20px 0px rgba(#000, 0.6);

    @media(max-width: 800px){
      height: auto;
    }
  }
}

.cam__description{
  color: #fff;
  width: 100%;
  text-align: center;
  display: block;
}

.cam__label{
  color: #fff;
  font-weight: bold;
  font-size: 1em;
  display: block;
  position: absolute;
  top: 0px;
  margin: 0;
  width: 100%;
  background: #3366ff;
  padding: 7px;
  text-align: center;
  z-index: 1000;
}

.cam__label--success{
  background: #00a201;
}

.cam__label--error{
  background: #ff2121;
}

.cam__label--loading{
  background: #ff6600;
}

.cam__btn-photo{
  position: absolute;
  bottom: 40px;
  margin: 0 auto;
  left: 0px;
  width: 250px;
  border-radius: 3px;
  right: 0px;
  padding: 0px 20px 0px 20px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #fff;
  z-index: 10000;
  border: solid #fff 4px;
  transition: all 0.3s linear 0s;
  outline: none;
  background: #0069FF;
  font-family: inherit;

  &:hover{
    background: #002F72;
  }

  img{
    width: 30px;
    margin-right: 10px;
  }
}
